/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-background: rgb(41,79,68);
  --body-color: rgb(41,79,68);
  --heading-color: black;
  --action-color: #009782;
  --action-hover-color: #4D4637;
  --nav-action-color: rgb(41,79,68);
  --white: #fff;
}

@layer components {
  .body-text {
    @apply mb-5 text-base text-gray-500 sm:text-lg;
  }
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 5;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: var(--action-color);
}

a:hover {
  color: var(--action-hover-color);
  text-decoration-color: var(--action-hover-color);
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
  color: var(--nav-action-color);
}

nav {
  padding: 0 16px;
}

main {
  padding: 16px 16px 50px;
  margin: 2rem auto 0;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  background: white;
}

.main--full main {
  padding: 16px 0 50px;
}

.main-default-padding {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  main {
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    margin: 2rem auto 4rem;
    padding: 25px 25px 50px;
  }
  .main-default-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
  .main--full main {
    padding: 25px 0 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

.button {
  @apply bg-maintainable-dark-green;
  border-radius: 15px;
  color: var(--white);
  display: inline-block;
  padding: 15px 20px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  transition: background-color 300ms ease-out;
  @media (min-width: 640px) {
    padding: 15px 40px;
  }
}

.button:hover {
  @apply bg-maintainable-darker-green;
  color: var(--white);
}

.hero-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2rem;
  padding: 2rem 0;
  text-align: center;
}

.hero-area--sponsorships {
  background-image: url('/images/maintainable-sponsorships-background.jpg');
}

div#episode_breadcrumbs ul {
  @apply m-0 p-0
}

div#episode_breadcrumbs a {
  text-decoration: none;
  @apply text-xs
}

article#episode_show_notes p {
  @apply text-sm font-normal text-gray-700 lg:text-base pb-2
}

ul {
  @apply list-disc list-inside hover:list-inside pl-4 my-4
}

article#episode_show_notes h2 {
  @apply text-base font-bold lg:text-3xl
}

article#episode_show_notes h3 {
  @apply text-base font-bold lg:text-2xl
}


article#episode_show_notes ul li {
  @apply text-base font-normal text-gray-700
}

article#episode_show_notes a {

}
